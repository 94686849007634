<template>
    <div class="tagMore">
        <HeadBar :titleName="tagName"></HeadBar>
        <div class="mian">
         <van-tabs
           class="tagTabs oepnX"
           type="card"
           swipeable
           animated
           line-width="24"
           title-active-color="#333333"
           title-inactive-color="rgba(153, 153, 153, 1)"
           line-height="6"
           v-model="activeName"
           @change="clickTag"
           sticky
         >
          <van-tab :title="item.label" v-for="(item, index) in filteredNavList" :key="index">
           <div class="topicPageContent">
            <PullRefresh
              v-if="!isLoading"
              :loading="loading"
              :refreshing="refreshing"
              :finished="finished"
              @onLoad="onLoad"
              @onRefresh="onRefresh"
              :isNoData="isNoData"
              :error="error"
              :hasAdvPagination="true"
              className="Page"
            >
             <div v-if="isAcg && kind !== 3" class="rowsBox">
              <HorizontalVideoBox
                v-for="(item,index) in list"
                :key="item.id"
                :currentIndex="index"
                :patternType="0"
                :isAcg="Boolean(isAcg)"
                :videoInfo="item"
                :videoList="list"
                class="videoBox"
              />
             </div>
             <div v-else-if="isAcg && kind === 3" class="videoRowsBox">
              <HorizontalVideoBox
                v-for="(item,index) in list"
                :key="item.id"
                :currentIndex="index"
                :patternType="0"
                :isAcg="Boolean(isAcg)"
                :videoInfo="item"
                :videoList="list"
                class="videoBox"
              />
             </div>
             <div v-else class="videoRowsBox">
              <HorizontalVideoBox
                v-for="(item,index) in list"
                :key="item.id"
                :currentIndex="index"
                :patternType="1"
                :isAcg="Boolean(isAcg)"
                :videoInfo="item"
                :videoList="list"
                class="videoBox"
              />
             </div>
            </PullRefresh>
            <Loading v-else />
           </div>
          </van-tab>
         </van-tabs>
        </div>
    </div>
</template>

<script>
    import HeadBar from "@/components/HeadBar";
    import PullRefresh from "@/components/PullRefresh"
    import VerticleThreeList from "@/components/VideoList/VerticleThreeList";
    import TransverselyTwoList from "@/components/VideoList/TransverselyTwoList";
    import {
     queryAcgMediaList, queryAcgTagsList,
     querySearchTagsMediaList, querySearchTagsVidList,
     queryTagsmediaList, queryVideoMediaList,
    } from "@/api/video"
    import Loading from "@/components/Loading/index.vue";
    import CommunityList from "@/components/community/CommunityList.vue";
    import HorizontalVideoBox from "@/components/HorizontalVideoBox/index.vue";
    import {AdType, getAdItem} from "@/utils/getConfig";
    export default {
        name: 'tagMore',
        components: {
         HorizontalVideoBox,
         Loading,
            HeadBar,
            PullRefresh,
        },
        watch: {
            $route(to, from) {
                if (to.path == from.path) {
                   let dataStr = this.$route.query.tagData;
                   let decodeData = window.atob(decodeURI(dataStr));
                   let tagData = JSON.parse(decodeURI(window.decodeURI(decodeData)));
                   this.kind = Number(this.$route.query.kind);
                   this.tagId = tagData.id;
                   this.tagName = tagData.name;
                   this.isAcg = tagData.isAcg;
                   this.list = [];
                   this.pageNumber = 1;
                   this.refreshing = true;
                   this.finished = false;
                   this.loading = true;
                   this.getList("refresh");
                }
            },
        },
        data() {
            return {
                isAcg: 0,
                activeName: 0,
                navTitle: "",
                tagId: [],
                tagName: "",
                kind: 0,
                list: [],
                pageNumber: 1,
                pageSize: 10,
                isLoading: true,
                loading: true,
                refreshing: false,
                finished: false,
                isNoData: false,
                error: false,
                navList: [
                 {
                  label: '最多观看',
                  value: 0,
                 },
                 {
                  label: '最多收藏',
                  value: 1,
                 },
                 {
                  label: '最新上架',
                  value: 2,
                 },
                ],
                advList: [],
            }
        },
        computed: {
         filteredNavList() {
          if (!this.isAcg) {
           return this.navList.filter(item => item.value !== 1);
          }
          return this.navList;
         },
        },
        created() {
            let dataStr = this.$route.query.tagData;
            let decodeData = window.atob(decodeURI(dataStr));
            let tagData = JSON.parse(decodeURI(window.decodeURI(decodeData)));
            this.kind = Number(this.$route.query.kind);
            this.tagId = tagData.id;
            this.tagName = tagData.name;
            this.isAcg = tagData.isAcg;
            this.advList = getAdItem(this.isAcg === 1 ? AdType.TIPIC_MORE : this.isAcg === 0 ? AdType.TIPIC_MORE_VERTICLE : null);
            this.getList();
        },
        methods: {
            clickTag(index) {
             if (index != null) {
              this.activeName = index;
             }
             this.pageNumber = 1;
             this.finished = false;
             this.loading = true;
             this.isLoading = true;
             this.isNoData = false;
             this.getList("refresh");
            },
            // 查询列表
            async getList(type) {
                // console.log(this.tagId, this.isAcg);
                let req0 = {
                   pageNumber: this.pageNumber,
                   pageSize: this.pageSize,
                   tid: this.tagId,
                   kind: this.kind,
                   sortType: this.navList[this.activeName].value,
                };

                let req1 = {
                   pageNumber: this.pageNumber,
                   pageSize: this.pageSize,
                   tagIDs: this.isAcg === 2 ? this.tagId.join(',') : null,
                   sortType: this.navList[this.activeName].value,
                   kind: this.kind,
                };

                let req2 = {
                  pageNumber: this.pageNumber,
                  pageSize: this.pageSize,
                  tagID: this.tagId,
                  sortType: this.activeName === 0 ? 3 : 1,
                  reqDate: new Date().toISOString(),
                };
                try {
                  let res = null;
                  if(this.isAcg == 1){
                    res = await this.$Api(queryAcgTagsList, req0);
                  } else if (this.isAcg == 2){
                    res = await this.$Api(querySearchTagsMediaList, req1);
                  }else{
                    res = await this.$Api(querySearchTagsVidList, req2);
                  }
                    // let res = await this.$Api(querySearchTagsMediaList, req);
                    this.loading = false;
                    this.refreshing = false;
                    this.isLoading = false;
                    if (res.code === 200) {
                        let list = this.isAcg === 2 ?res.data.mediaList:res.data.list || [];
                        if (type === 'refresh') {
                            this.list = list;
                        } else {
                            this.list = this.list.concat(list);
                        }
                        if(this.advList.length > 0){
                         let advNum = Math.floor(this.list.length / 4);
                         this.list = JSON.parse(JSON.stringify(this.list));

                         for (let i = 0; i < advNum; i++) {
                          let advItem = this.advList[i % this.advList.length];
                          this.list.splice(5 * i + 4, 0, {
                           adv: advItem,
                          });
                         }
                        }
                        if (this.list.length <= 0) {
                            this.isNoData = true
                        }
                        if (!res.data.hasNext) {
                            this.finished = true;
                            return;
                        }
                    } else {
                        this.error = true;
                    }
                } catch (error) {
                    this.error = true;
                    this.loading = false;
                }


            },
            // 上啦加载
            onLoad() {
                this.loading = true;
                if (this.error) {
                    this.error = false;
                } else {
                    this.pageNumber++
                }
                this.getList();
            },
            // 下拉刷新
            onRefresh() {
                this.pageNumber = 1;
                this.refreshing = true;
                this.finished = false;
                this.loading = true;
                this.getList("refresh");
            },
        }
    }
</script>

<style scoped lang="scss">
@import "@/assets/css/main.scss";
.tagMore {
    height: 100%;

    .mian {
      height: calc(100% - 45px);
      //padding: 0 10px;
      box-sizing: border-box;
      background: rgba(245, 245, 245, 1);
      overflow-y: auto;
      -webkit-overflow-scrolling: touch;
     .tagTabs {
       padding-top: 20px;
       @include cardTab();
      /deep/ .van-tabs__wrap {
       padding-left: 10px;
       padding-right: 10px;
      }
      /deep/ .van-sticky--fixed {
       background: #f5f5f5;
       top: 43px;
       height: 50px;
       padding-top: 18px;
      }
     }
     .topicPageContent {
      .rowsBox {
       // margin-top: 12px;
       padding: 0 10px;
       display: flex;
       flex-wrap: wrap;
       justify-content: space-between;
       .videoBox {
        width: 113px;
        /deep/ .cover {
         height: 166px;
        }
        margin-top: 12px;
       }
      }

      .videoRowsBox {
       // margin-top: 12px;
       padding: 0 10px;
       display: flex;
       flex-wrap: wrap;
       justify-content: space-between;
       .videoBox {
        width: 173px;
        /deep/ .cover {
         height: 98px;
        }
        margin-top: 12px;
       }
      }
     }
    }
}
</style>
